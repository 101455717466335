import { RouteObject } from "react-router-dom";
import { htzOffersRoutes } from "@/routes/gesec/processes/[processId]/htz/offers/offers-routes";
import OverviewRoute from "@/routes/gesec/processes/[processId]/htz/overview/overview";
import ContactRoute from "@/routes/gesec/processes/[processId]/htz/contact/contact";
import OffersRoute from "@/routes/gesec/processes/[processId]/htz/offers/offers-route";
import OrdersRoute from "@/routes/gesec/processes/[processId]/htz/orders/orders-route";
import { htzOrdersRoutes } from "@/routes/gesec/processes/[processId]/htz/orders/orders-routes";
import ConfirmationsRoute from "@/routes/gesec/processes/[processId]/htz/confirmations/confirmations-route";
import { htzConfirmationsRoutes } from "@/routes/gesec/processes/[processId]/htz/confirmations/confirmations-routes";

export const htzRoutes: RouteObject[] = [
  {
    element: <OverviewRoute />,
    index: true,
  },
  {
    path: "contact",
    element: <ContactRoute />,
  },
  {
    path: "appointments",
    element: <div>Termine</div>,
  },
  {
    path: "offers",
    element: <OffersRoute />,
    children: htzOffersRoutes,
  },
  {
    path: "orders",
    element: <OrdersRoute />,
    children: htzOrdersRoutes,
  },
  {
    path: "confirmations",
    element: <ConfirmationsRoute />,
    children: htzConfirmationsRoutes,
  },
  {
    path: "crm-opportunity",
    element: <div>TODO external link</div>,
  },
];
