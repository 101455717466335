import { Checkbox } from "@/shared/components/ui/checkbox";
import { CheckedState } from "@radix-ui/react-checkbox";
import { useToast } from "@/shared/hooks/use-toast";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import {
  useHtzAhuTemplateCatalogPutActiveCatalogMutation,
  useHtzAhuTemplateCatalogShowActiveCatalogQuery,
} from "@/services/backend/htz/ahu/template-catalog-service";

export interface ActiveTemplateCatalogCheckboxProps {
  catalogId: string;
}

export function ActiveTemplateCatalogCheckbox({
  catalogId,
}: ActiveTemplateCatalogCheckboxProps) {
  const { data: activeCatalog } =
    useHtzAhuTemplateCatalogShowActiveCatalogQuery({});

  const [put, { isLoading, error, reset }] =
    useHtzAhuTemplateCatalogPutActiveCatalogMutation();

  const { toast } = useToast();

  if (error) {
    toast({
      ...parseRTKQueryError(error),
      variant: "destructive",
    });
    reset();
  }

  if (!activeCatalog) {
    return null;
  }

  const checked = activeCatalog.catalogId === catalogId;
  const onCheckedChange = (newState: CheckedState) => {
    if (isLoading) {
      return;
    }

    switch (newState) {
      case true:
        put({ catalogId });
        break;
      case false:
        put({ catalogId: null });
        break;
      case "indeterminate":
        break;
      default:
        break;
    }
  };

  return (
    <Checkbox
      checked={checked}
      onCheckedChange={onCheckedChange}
      className={isLoading ? "animate-pulse" : ""}
      disabled={isLoading}
    />
  );
}
