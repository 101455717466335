import { PositionsTable } from "@/routes/gesec/processes/[processId]/htz/_shared/positions/positions-table";
import {
  PositionsTableContext,
  PositionsTableContextInterface,
} from "@/routes/gesec/processes/[processId]/htz/_shared/positions/positions-table-context";
import { useConfirmationContext } from "@/routes/gesec/processes/[processId]/htz/confirmations/_components/confirmation-context";
import { useMemo } from "react";

export function PositionsRoute() {
  const {
    confirmation,
    onConfirmationChange,
    catalog,
    airHandlingUnits,
    validationError,
    setValidationError,
  } = useConfirmationContext();

  const value: PositionsTableContextInterface = useMemo(
    () => ({
      positions: confirmation.positions,
      onPositionsChange: (positions) =>
        onConfirmationChange({ ...confirmation, positions }),
      immutable: confirmation.immutable,
      validationError,
      setValidationError,
      catalog,
      airHandlingUnits,
    }),
    [
      confirmation,
      onConfirmationChange,
      validationError,
      setValidationError,
      catalog,
      airHandlingUnits,
    ],
  );

  return (
    <PositionsTableContext.Provider value={value}>
      <PositionsTable />
    </PositionsTableContext.Provider>
  );
}
