import {
  AirHandlingUnit,
  Component,
} from "@/services/backend/htz/ahu/air-handling-unit";
import {
  DataTable,
  useDataTableController,
} from "@/shared/components/ui/data-table";
import { ColumnDef } from "@tanstack/react-table";

export function AhuComponentsTable({ ahu }: { ahu: AirHandlingUnit }) {
  const { components } = ahu;
  const table = useDataTableController(tableColumns, components);

  return <DataTable columns={tableColumns} table={table} />;
}

const tableColumns: ColumnDef<Component>[] = [
  {
    accessorKey: "name",
  },
  {
    accessorKey: "type",
  },
];
