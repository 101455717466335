import {
  DataTable,
  DataTableBrowserPagination,
  DataTableContainer,
  useDataTableController,
} from "@/shared/components/ui/data-table";
import { ColumnDef } from "@tanstack/react-table";
import t from "@/lang/lang";
import {
  Template,
  TemplateCatalog,
} from "@/services/backend/htz/ahu/template-catalog";
import { DeleteTemplateCatalogTemplateDialog } from "@/routes/base-data/processes/htz/template-catalogs/[id]/_components/delete-template-catalog-template-dialog";
import { PutTemplateCatalogTemplateDialog } from "@/routes/base-data/processes/htz/template-catalogs/[id]/_components/put-template-catalog-template-dialog";

export function TemplateCatalogTemplatesTable({
  catalog,
}: {
  catalog: TemplateCatalog;
}) {
  const table = useDataTableController(tableColumns, catalog.templates);

  return (
    <DataTableContainer>
      <div className="border-b">
        <DataTable
          table={table}
          columns={tableColumns}
          className="border-none"
        />
      </div>
      <div className="px-4 pb-2">
        <DataTableBrowserPagination table={table} />
      </div>
    </DataTableContainer>
  );
}

const tableColumns: ColumnDef<Template>[] = [
  {
    header: t("Name"),
    accessorKey: "name",
    cell: ({ row }) => <span className="">{row.original.name}</span>,
  },
  {
    header: t("Details"),
    accessorKey: "details",
  },
  {
    header: t("Gruppe"),
    accessorKey: "group",
  },
  {
    header: t("Piktogramm"),
    id: "img",
    cell: ({ row }) => (
      <img
        src={`data:image/svg+xml;base64,${row.original.img}`}
        alt={t("Piktogramm")}
        className="h-8"
      />
    ),
  },
  {
    header: t("Größenfaktor"),
    accessorKey: "sizeFactor",
    cell: ({ row }) => (
      <div className="text-right">{row.original.sizeFactor}</div>
    ),
  },
  {
    header: t("Aktionen"),
    id: "actions",
    cell: ({ row }) => (
      <div className="flex items-center space-x-2" key={row.original.id}>
        <PutTemplateCatalogTemplateDialog
          catalogId={row.original.catalogId}
          template={row.original}
        />
        <DeleteTemplateCatalogTemplateDialog
          catalogId={row.original.catalogId}
          positionId={row.original.id}
        />
      </div>
    ),
  },
];
