export interface Catalog {
  id: string;
  name: string;
  companyId: string;
  positions: CatalogPosition[];
}

export function addPosition(
  catalog: Catalog,
  position: CatalogPosition,
): Catalog {
  if (catalog.positions.some((pos) => pos.id === position.id)) {
    return catalog;
  }

  return {
    ...catalog,
    positions: [...catalog.positions, position],
  };
}

export function updatePosition(
  catalog: Catalog,
  position: CatalogPosition,
): Catalog {
  return {
    ...catalog,
    positions: catalog.positions.map((pos) => {
      if (pos.id === position.id) {
        return position;
      }
      return pos;
    }),
  };
}

export function deletePosition(catalog: Catalog, positionId: string): Catalog {
  return {
    ...catalog,
    positions: catalog.positions.filter((pos) => pos.id !== positionId),
  };
}

export interface CatalogPosition {
  id: string;
  catalogId: string;
  category: string;
  title: string;
  copyTitle: boolean;
  description: string;
  copyDescription: boolean;
  unitPrice: number;
  copyUnitPrice: boolean;
}
