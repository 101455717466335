import { useParams } from "react-router-dom";
import { useHtzOfferShowQuery } from "@/services/backend/htz/offers/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { Card } from "@/shared/components/ui/card";
import t from "@/lang/lang";
import { PersistenceContextProvider } from "@/shared/lib/persistence-state/provider";
import {
  MilestonePageContent,
  MilestonePageContentMenu,
} from "@/shared/components/layout/milestone-page";
import {
  TabsNav,
  TabsNavContent,
  TabsNavLink,
  TabsNavList,
} from "@/shared/components/layout/tabs-nav";
import { ArtefactNumberById } from "@/shared/components/domain/numbers/artefact-number";
import { PersistenceStateUsingContext } from "@/shared/lib/persistence-state/persistence-state-info";
import {
  OfferContextProvider,
  useOfferContext,
} from "@/routes/gesec/processes/[processId]/htz/offers/_components/offer-context";
import {
  useHtzCatalogShowActiveCatalogQuery,
  useHtzCatalogShowQuery,
} from "@/services/backend/htz/catalog/service";
import { useHtzAirHandlingUnitListQuery } from "@/services/backend/htz/ahu/service";
import { OfferPdf } from "@/routes/gesec/processes/[processId]/htz/offers/[offerId]/_components/offer-pdf";
import { DeleteOfferDialog } from "@/routes/gesec/processes/[processId]/htz/offers/[offerId]/_components/delete-offer-dialog";
import { hasFieldError } from "@/shared/app-lib/errors/validation-error";

export function OfferRoute() {
  const { offerId } = useParams();

  const { offer, catalog, airHandlingUnits, isLoading, error } =
    useOfferRouteDependencies(offerId!);

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return (
      <Card className="animate-pulse p-6 text-muted-foreground">
        {t("Lade Daten...")}
      </Card>
    );
  }

  return (
    <PersistenceContextProvider>
      <OfferContextProvider
        offer={offer!}
        catalog={catalog!}
        airHandlingUnits={airHandlingUnits!}
      >
        <OfferPage />
      </OfferContextProvider>
    </PersistenceContextProvider>
  );
}

function useOfferRouteDependencies(offerId: string) {
  const {
    data: offer,
    isLoading: offerIsLoading,
    error: offerError,
  } = useHtzOfferShowQuery({
    id: offerId!,
  });

  const {
    data: activeCatalog,
    isLoading: activeCatalogIsLoading,
    error: activeCatalogError,
  } = useHtzCatalogShowActiveCatalogQuery(
    {
      companyId: offer?.companyId ?? "",
    },
    {
      skip: offer === undefined,
    },
  );

  const {
    data: catalog,
    isLoading: catalogIsLoading,
    error: catalogError,
  } = useHtzCatalogShowQuery(
    {
      id: activeCatalog?.catalogId ?? "",
    },
    {
      skip: activeCatalog === undefined || activeCatalog?.catalogId === null,
    },
  );

  const {
    data: ahuList,
    isLoading: ahuIsLoading,
    error: ahuError,
  } = useHtzAirHandlingUnitListQuery(
    {
      customer: {
        active: offer !== undefined,
        values: [offer?.customerId ?? ""],
      },
    },
    {
      skip: offer === undefined,
    },
  );
  const airHandlingUnits = ahuList?.airHandlingUnits ?? [];

  const isLoading =
    offerIsLoading ||
    activeCatalogIsLoading ||
    catalogIsLoading ||
    ahuIsLoading;

  const error = (() => {
    switch (true) {
      case offerError !== undefined:
        return offerError;
      case activeCatalogError !== undefined:
        return activeCatalogError;
      case catalogError !== undefined:
        return catalogError;
      case ahuError !== undefined:
        return ahuError;
      case activeCatalog?.catalogId === null:
        // fake 404 error
        return {
          status: 404,
        };
      default:
        return undefined;
    }
  })();

  return {
    offer,
    catalog,
    airHandlingUnits,
    isLoading,
    error,
  };
}

function OfferPage() {
  const { offer, validationError } = useOfferContext();

  const hasDataError = hasFieldError(validationError, "date");
  const hasPosError = hasFieldError(validationError, "positions");
  const hasAddrError = hasFieldError(
    validationError,
    "postalAddress",
    "serviceAddress",
  );
  const hasRepError = hasFieldError(validationError, "representative");

  return (
    <MilestonePageContent>
      <TabsNav>
        <MilestonePageContentMenu>
          <div className="flex items-center space-x-4">
            <TabsNavList>
              <TabsNavLink to="data" hasValidationError={hasDataError}>
                {t("Angebot")}
              </TabsNavLink>
              <TabsNavLink to="positions" hasValidationError={hasPosError}>
                {t("Leistungen")}
              </TabsNavLink>
              <TabsNavLink to="addresses" hasValidationError={hasAddrError}>
                {t("Adressen")}
              </TabsNavLink>
              <TabsNavLink to="representative" hasValidationError={hasRepError}>
                {t("Betreuer")}
              </TabsNavLink>
            </TabsNavList>
            <span className="font-mono text-lg text-gray-900">
              <ArtefactNumberById artefactId={offer.id} />
            </span>
          </div>
          <div className="flex items-center space-x-2">
            <PersistenceStateUsingContext className="mr-2" />
            <DeleteOfferDialog offerId={offer.id} />
            <OfferPdf offer={offer} />
          </div>
        </MilestonePageContentMenu>
        <TabsNavContent />
      </TabsNav>
    </MilestonePageContent>
  );
}
