import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import { useHtzCatalogShowQuery } from "@/services/backend/htz/catalog/service";
import { NavLink, useParams } from "react-router-dom";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { CompanyName } from "@/shared/components/domain/company/company-name";
import { ArrowLeft, RefreshCw } from "lucide-react";
import t from "@/lang/lang";
import { CatalogPositionsTable } from "@/routes/base-data/processes/htz/catalogs/[id]/_components/catalog-positions-table";
import { PutCatalogPositionDialog } from "@/routes/base-data/processes/htz/catalogs/[id]/_components/put-catalog-position-dialog";
import { Button } from "@/shared/components/ui/button";

export function CatalogRoute() {
  const { catalogId } = useParams();

  const {
    data: catalog,
    isLoading,
    error,
  } = useHtzCatalogShowQuery({
    id: catalogId!,
  });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return (
      <Card>
        <CardHeader>
          <CardTitle className="flex space-x-4">
            <RefreshCw className="animate-spin" />
            <span>{t("Lade Katalog")}</span>
          </CardTitle>
        </CardHeader>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader className="relative">
        <CardTitle>
          <NavLink to="./..">
            <Button variant="outline" size="sm" className="m-0 mb-4">
              <ArrowLeft className="mr-2 h-4 w-4" />
              <span>{t("Übersicht")}</span>
            </Button>
          </NavLink>
          <div>{catalog!.name}</div>
        </CardTitle>
        <CardDescription>
          <CompanyName companyId={catalog!.companyId} />
        </CardDescription>
        <div className="absolute right-4 top-4">
          <PutCatalogPositionDialog catalogId={catalog!.id} />
        </div>
      </CardHeader>
      <CatalogPositionsTable catalog={catalog!} />
    </Card>
  );
}
