import { Navigate, RouteObject } from "react-router-dom";
import { CatalogRoute } from "@/routes/base-data/processes/htz/catalogs/[id]/catalog-route";
import { CatalogsRoute } from "@/routes/base-data/processes/htz/catalogs/catalogs-route";
import { TemplateCatalogsRoute } from "@/routes/base-data/processes/htz/template-catalogs/template-catalogs-route";
import { TemplateCatalogRoute } from "@/routes/base-data/processes/htz/template-catalogs/[id]/template-catalog-route";

export const htzBaseDataRoutes: RouteObject[] = [
  {
    index: true,
    element: <Navigate to="catalogs" replace />,
  },
  {
    path: "catalogs",
    element: <CatalogsRoute />,
  },
  {
    path: "catalogs/:catalogId",
    element: <CatalogRoute />,
  },
  {
    path: "template-catalogs",
    element: <TemplateCatalogsRoute />,
  },
  {
    path: "template-catalogs/:templateCatalogId",
    element: <TemplateCatalogRoute />,
  },
];
