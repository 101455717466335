import { applyNodeChanges, NodeChange, XYPosition } from "@xyflow/react";
import { v4 } from "uuid";
import {
  SizeFactor,
  Template,
} from "@/services/backend/htz/ahu/template-catalog";

export interface AirHandlingUnit {
  id: string;
  name: string;
  customerId: string;
  components: Component[];
}

/**
 * A Component represents a building block of an air handling unit.
 * This could be filter chambers, cross flow heat exchangers or similar.
 *
 * Component is designed such that it can be passed as a Node into
 * a ReactFlow diagram. While this design strongly couples the
 * implementation of a component to ReactFlow and vice versa, it was
 * the simplest way to integrate both at the time. The coupling
 * in trade-off to more complex designs was willingly accepted.
 */
export interface Component {
  // The component id is also used as a ReactFlow id.
  id: string;
  name: string;
  // ReactFlow specific fields to make Component satisfy the NodeBase constraint.
  type: string;
  position: XYPosition;
  data: { name: string; img: string; sizeFactor: SizeFactor; details: string };
}

export function newFromTemplate(template: Template): Component {
  const id = v4();
  return {
    id,
    name: template.name,
    type: template.type,
    position: { x: 0, y: 0 },
    data: {
      name: template.name,
      img: template.img,
      sizeFactor: template.sizeFactor,
      details: template.details,
    },
  };
}

/**
 * addNode allows the AirHandlingUnit to be used with react flow.
 * Since a Component is a valid Node in react flow
 * @param ahu
 * @param node
 */
export function addNode(
  ahu: AirHandlingUnit,
  node: Component,
): AirHandlingUnit {
  const newComponent = {
    ...node,
    id: v4(),
  };

  return {
    ...ahu,
    components: [...ahu.components, newComponent],
  };
}

/**
 * changeNodes enables integrating an AirHandlingUnit with
 * React Flow. It can be used in event handlers passed to
 * a ReactFlow component.
 * @param ahu
 * @param changes
 */
export function changeNodes(
  ahu: AirHandlingUnit,
  changes: NodeChange<Component>[],
): AirHandlingUnit {
  return {
    ...ahu,
    components: applyNodeChanges(changes, ahu.components),
  };
}
