import { Position } from "@/services/backend/htz/position/position";
import { createContext, useContext } from "react";
import { ValidationError } from "@/shared/app-lib/errors/validation-error";
import { AirHandlingUnit } from "@/services/backend/htz/ahu/air-handling-unit";
import { Catalog } from "@/services/backend/htz/catalog/catalog";

export interface PositionsTableContextInterface {
  positions: Position[];
  onPositionsChange: (positions: Position[]) => void;
  validationError: ValidationError | null;
  setValidationError: (err: ValidationError | null) => void;
  airHandlingUnits: AirHandlingUnit[];
  catalog: Catalog;
  immutable: boolean;
}

export const PositionsTableContext =
  createContext<PositionsTableContextInterface>({
    positions: [],
    onPositionsChange: () => null,
    validationError: null,
    setValidationError: () => null,
    catalog: {} as Catalog,
    airHandlingUnits: [],
    immutable: false,
  });

export function usePositionTableContext() {
  return useContext(PositionsTableContext);
}
