import {
  DataTable,
  DataTableContainer,
  useDataTableController,
} from "@/shared/components/ui/data-table";
import { ColumnDef } from "@tanstack/react-table";
import t from "@/lang/lang";
import { NavLink } from "react-router-dom";
import { Button } from "@/shared/components/ui/button";
import { ActiveTemplateCatalogCheckbox } from "@/routes/base-data/processes/htz/template-catalogs/_components/active-template-catalog-checkbox";
import { DeleteTemplateCatalogDialog } from "@/routes/base-data/processes/htz/template-catalogs/_components/delete-template-catalog-dialog";
import { NewTemplateCatalogDialog } from "@/routes/base-data/processes/htz/template-catalogs/_components/new-template-catalog-dialog";
import { useHtzAhuTemplateCatalogListQuery } from "@/services/backend/htz/ahu/template-catalog-service";
import { TemplateCatalog } from "@/services/backend/htz/ahu/template-catalog";
import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";

export function TemplateCatalogTable() {
  const { data: list } = useHtzAhuTemplateCatalogListQuery({});

  const catalogs = list?.catalogs || [];

  const table = useDataTableController(tableColumns, catalogs);

  return (
    <Card>
      <CardHeader className="relative">
        <CardTitle>{t("RLT-Komponenten Kataloge")}</CardTitle>
        <CardDescription>
          {t(
            "Hier können Sie Kataloge mit Vorlagen zur Definition von RLT-Anlagen verwalten. Es gibt nur einen einheitlichen Komponentenkatalog für alle Unternehmen.",
          )}
        </CardDescription>
        <div className="absolute right-4 top-4">
          <NewTemplateCatalogDialog />
        </div>
      </CardHeader>
      <DataTableContainer>
        <DataTable
          table={table}
          columns={tableColumns}
          className="border-none"
        />
      </DataTableContainer>
    </Card>
  );
}

const tableColumns: ColumnDef<TemplateCatalog>[] = [
  {
    header: t("Aktiv"),
    id: "activeCatalog",
    cell: ({ row }) => (
      <div className="ml-4">
        <ActiveTemplateCatalogCheckbox catalogId={row.original.id} />
      </div>
    ),
  },
  {
    header: t("Katalogname"),
    accessorKey: "name",
    cell: ({ row }) => {
      const path = `/base-data/processes/htz/template-catalogs/${row.original.id}`;
      return (
        <NavLink to={path}>
          <Button variant="link">{row.original.name}</Button>
        </NavLink>
      );
    },
  },
  {
    id: "actions",
    header: t("Aktionen"),
    cell: ({ row }) => (
      <div>
        <DeleteTemplateCatalogDialog catalog={row.original} />
      </div>
    ),
  },
];
