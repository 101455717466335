import {
  createContext,
  PropsWithChildren,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { AirHandlingUnit } from "@/services/backend/htz/ahu/air-handling-unit";
import { Catalog } from "@/services/backend/htz/catalog/catalog";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import {
  rtkErrIsValidationError,
  ValidationError,
} from "@/shared/app-lib/errors/validation-error";
import { Confirmation } from "@/services/backend/htz/confirmations/confirmation";
import { useHtzConfirmationPutMutation } from "@/services/backend/htz/confirmations/service";
import { Order } from "@/services/backend/htz/orders/order";
import { Offer } from "@/services/backend/htz/offers/offer";

interface ConfirmationContextInterface {
  confirmation: Confirmation;
  onConfirmationChange: (confirmation: Confirmation) => void;
  validationError: ValidationError | null;
  setValidationError: (err: ValidationError | null) => void;
  airHandlingUnits: AirHandlingUnit[];
  catalog: Catalog;
  orders: Order[];
  offers: Offer[];
}

export const ConfirmationContext = createContext<ConfirmationContextInterface>({
  confirmation: {} as Confirmation,
  onConfirmationChange: () => null,
  validationError: null,
  setValidationError: () => null,
  catalog: {} as Catalog,
  airHandlingUnits: [],
  orders: [],
  offers: [],
});

export function useConfirmationContext() {
  return useContext(ConfirmationContext);
}

export function ConfirmationContextProvider({
  children,
  confirmation: propConfirmation,
  catalog: propCatalog,
  airHandlingUnits: propAirHandlingUnits,
  offers: propOffers,
  orders: propsOrders,
}: {
  children: ReactNode;
  confirmation: Confirmation;
  catalog: Catalog;
  airHandlingUnits: AirHandlingUnit[];
  offers: Offer[];
  orders: Order[];
}) {
  // idea would be to use index db here for offline availability
  const [confirmation, setConfirmation] = useState<Confirmation>();
  const [catalog, setCatalog] = useState<Catalog>();
  const [airHandlingUnits, setAirHandlingUnits] = useState<AirHandlingUnit[]>();
  const [offers, setOffers] = useState<Offer[]>();
  const [orders, setOrders] = useState<Order[]>();
  const [validationError, setValidationError] =
    useState<ValidationError | null>(null);

  // after vector clocks are added, they can be used to
  // update the confirmation here.
  if (!confirmation || confirmation.id !== propConfirmation.id) {
    setConfirmation(propConfirmation);
  }

  // Neither, the catalog nor the air handling units are mutated within the
  // confirmation context. Any value coming is must be an updated server value.
  if (!catalog || catalog !== propCatalog) {
    setCatalog(propCatalog);
  }
  if (!airHandlingUnits || airHandlingUnits !== propAirHandlingUnits) {
    setAirHandlingUnits(propAirHandlingUnits);
  }
  if (!offers || offers !== propOffers) {
    setOffers(propOffers);
  }
  if (!orders || orders !== propsOrders) {
    setOrders(propsOrders);
  }

  const onConfirmationChange = (changedConfirmation: Confirmation) => {
    setConfirmation(changedConfirmation);
  };

  const value = useMemo(
    () => ({
      confirmation: confirmation!,
      onConfirmationChange,
      validationError,
      setValidationError,
      airHandlingUnits: airHandlingUnits!,
      catalog: catalog!,
      offers: offers!,
      orders: orders!,
    }),
    [airHandlingUnits, catalog, confirmation, validationError, orders, offers],
  );

  return (
    <ConfirmationContext.Provider value={value}>
      <ConfirmationUpdater>{children}</ConfirmationUpdater>
    </ConfirmationContext.Provider>
  );
}

function ConfirmationUpdater({ children }: PropsWithChildren) {
  // this should only run when online
  const { confirmation, setValidationError } = useConfirmationContext();
  const [put, { isLoading, error, isSuccess, reset }] =
    useHtzConfirmationPutMutation();

  useEffect(() => {
    if (rtkErrIsValidationError(error)) {
      setValidationError(error.data);
    }
  }, [error, setValidationError]);

  useDebouncedMutationWithPersistenceStateContextUpdate(
    confirmation,
    put,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
    {
      toastError: true,
    },
  );

  return children;
}
