import {
  DataTable,
  DataTableContainer,
  useDataTableController,
} from "@/shared/components/ui/data-table";
import { NewCatalogDialog } from "@/routes/base-data/processes/htz/catalogs/_components/new-catalog-dialog";
import { ColumnDef } from "@tanstack/react-table";
import { Catalog } from "@/services/backend/htz/catalog/catalog";
import { CompanyName } from "@/shared/components/domain/company/company-name";
import t from "@/lang/lang";
import { useHtzCatalogListQuery } from "@/services/backend/htz/catalog/service";
import { NavLink } from "react-router-dom";
import { Button } from "@/shared/components/ui/button";
import { DeleteCatalogDialog } from "@/routes/base-data/processes/htz/catalogs/_components/delete-catalog-dialog";
import { ActiveCatalogCheckbox } from "@/routes/base-data/processes/htz/catalogs/_components/active-catalog-checkbox";
import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";

export function CatalogTable() {
  const { data: list } = useHtzCatalogListQuery({});

  const catalogs = list?.catalogs || [];

  const table = useDataTableController(tableColumns, catalogs);

  return (
    <Card>
      <CardHeader className="relative">
        <CardTitle>{t("Leistungskataloge")}</CardTitle>
        <CardDescription>
          {t(
            "Hier können Sie Kataloge mit Vorlagen zur Definition von Positionen von HTZ Dienstleistungen verwalten.",
          )}
        </CardDescription>
        <div className="absolute right-4 top-4">
          <NewCatalogDialog />
        </div>
      </CardHeader>
      <DataTableContainer>
        <DataTable table={table} columns={tableColumns} />
      </DataTableContainer>
    </Card>
  );
}

const tableColumns: ColumnDef<Catalog>[] = [
  {
    header: t("Aktiv"),
    id: "activeCatalog",
    cell: ({ row }) => (
      <div className="ml-4">
        <ActiveCatalogCheckbox
          companyId={row.original.companyId}
          catalogId={row.original.id}
        />
      </div>
    ),
  },
  {
    header: t("Katalogname"),
    accessorKey: "name",
    cell: ({ row }) => {
      const path = `/base-data/processes/htz/catalogs/${row.original.id}`;
      return (
        <NavLink to={path}>
          <Button variant="link">{row.original.name}</Button>
        </NavLink>
      );
    },
  },
  {
    header: t("Unternehmen"),
    accessorKey: "companyId",
    cell: ({ row }) => <CompanyName companyId={row.original.companyId} />,
  },
  {
    id: "actions",
    header: t("Aktionen"),
    cell: ({ row }) => (
      <div>
        <DeleteCatalogDialog catalog={row.original} />
      </div>
    ),
  },
];
