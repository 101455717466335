import { useEffect, useState } from "react";
import { usePersistenceContext } from "@/shared/lib/persistence-state/context";
import { PersistenceState } from "@/shared/lib/persistence-state/persistence-state";
import { useToast } from "@/shared/hooks/use-toast";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";

export function useDebounce<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}

export function useDebouncedMutation<T>(
  request: T,
  mutation: (request: T) => void,
  mutationIsLoading: boolean,
  mutationError: unknown,
  mutationIsSuccess: boolean,
  mutationReset: () => void,
  delay: number,
) {
  // The useDebounceMutation hook will run on the first render of the component.
  // The request will have some state at this point. However, no user-action has
  // taken place yet. Therefore, there is no need to run the mutation for this
  // initial state. Keep in mind: The useState hook will not update the initialRequest
  // on re-renders of useDebounceMutation.
  const [initialRequest] = useState<T>(request);

  const [persistenceState, setPersistenceState] = useState<PersistenceState>(
    PersistenceState.SAVED,
  );

  // The persistence state is set so saving even though
  // the debouncedMutation is not updated yet and the
  // save process has not started yet. However,
  // an intermediate state like "edited" has little value
  // if only shown for less than a second. It would just
  // cause the UI to flicker around the different states.
  useEffect(() => {
    if (request !== initialRequest) {
      setPersistenceState(PersistenceState.SAVING);
    }
  }, [initialRequest, request]);

  // Debounce the request to avoid too many requests.
  const debouncedRequest = useDebounce(request, delay);

  // Execute the mutation when the debounced request changes.
  useEffect(() => {
    // Prevent the unnecessary execution of the mutation for the
    // initial state. See comment above.
    if (debouncedRequest === initialRequest) {
      return;
    }
    mutation(debouncedRequest);
  }, [debouncedRequest, initialRequest, mutation]);

  // Update the persistence status based on the save state
  // useEffect is needed to avoid an infinite loop -> mutation cannot be reset here
  useEffect(() => {
    // The second predicate avoids unnecessary re-renders. The state
    // may already be set to SAVING in the updateCalculation function.
    if (mutationIsLoading && persistenceState !== PersistenceState.SAVING) {
      setPersistenceState(PersistenceState.SAVING);
      return;
    }
    if (mutationError && persistenceState !== PersistenceState.ERROR) {
      setPersistenceState(PersistenceState.ERROR);
    }
  }, [mutationIsLoading, mutationError, persistenceState]);

  // Reset the persistence status and mutation when the mutation is successful
  if (mutationIsSuccess) {
    setPersistenceState(PersistenceState.SAVED);
    mutationReset();
  }

  return { persistenceState };
}

/**
 * Interface for the options of the
 * useDebouncedMutationWithPersistenceStateContextUpdate hook.
 */
export interface Options {
  /**
   * If set to true, a toast will be shown when an error occurs.
   * This will also reset the mutation.
   */
  toastError?: boolean;
  /**
   * If set to true, a toast will be shown when the mutation
   * is successful. This will also reset the mutation.
   */
  toastSuccess?: boolean;
}

export function useDebouncedMutationWithPersistenceStateContextUpdate<T>(
  request: T,
  mutation: (request: T) => void,
  mutationIsLoading: boolean,
  mutationError: unknown,
  mutationIsSuccess: boolean,
  mutationReset: () => void,
  delay: number,
  options: Options = { toastError: false, toastSuccess: false },
) {
  const { persistenceState } = useDebouncedMutation(
    request,
    mutation,
    mutationIsLoading,
    mutationError,
    mutationIsSuccess,
    mutationReset,
    delay,
  );
  const { setPersistenceState } = usePersistenceContext();
  useEffect(() => {
    setPersistenceState(persistenceState);
  }, [setPersistenceState, persistenceState]);

  const { toast } = useToast();
  useEffect(() => {
    if (options?.toastError && mutationError) {
      toast({
        ...parseRTKQueryError(mutationError, false),
        variant: "destructive",
      });
      mutationReset();
    }
  });

  return { persistenceState };
}
