export interface TemplateCatalog {
  id: string;
  name: string;
  templates: Template[];
}

export function addTemplate(
  catalog: TemplateCatalog,
  template: Template,
): TemplateCatalog {
  return {
    ...catalog,
    templates: [...catalog.templates, template],
  };
}

export function updateTemplate(
  catalog: TemplateCatalog,
  template: Template,
): TemplateCatalog {
  return {
    ...catalog,
    templates: catalog.templates.map((templ) => {
      if (templ.id === template.id) {
        return template;
      }
      return templ;
    }),
  };
}

export function deleteTemplate(
  catalog: TemplateCatalog,
  templateId: string,
): TemplateCatalog {
  return {
    ...catalog,
    templates: catalog.templates.filter((templ) => templ.id !== templateId),
  };
}

export interface Template {
  id: string;
  catalogId: string;
  name: string;
  details: string;
  group: string;
  type: string;
  img: string;
  sizeFactor: SizeFactor;
}

export type SizeFactor = 1 | 2 | 4;
