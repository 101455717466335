import { backendApi } from "@/services/backend/api";
import { Filter } from "@/shared/lib/filter/filter";
import { providesEntity, providesList } from "@/services/backend/tag-helpers";
import { Catalog } from "@/services/backend/htz/catalog/catalog";

const catalogApi = backendApi
  .enhanceEndpoints({
    addTagTypes: ["HtzCatalog", "HtzActiveCatalog"],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      htzCatalogPut: build.mutation<Catalog, PutRequest>({
        query: (request) => ({
          url: `/htz/catalog`,
          method: "PUT",
          body: request,
        }),
        invalidatesTags: (_result, _e, request) => [
          { type: "HtzCatalog", id: request.id },
          "HtzCatalog",
        ],
      }),
      htzCatalogShow: build.query<Catalog, ShowRequest>({
        query: (request) => ({
          url: `/htz/catalog`,
          method: "GET",
          params: request,
        }),
        providesTags: providesEntity("HtzCatalog"),
      }),
      htzCatalogList: build.query<List, ListRequest>({
        query: (request) => ({
          url: `/htz/catalog/list`,
          method: "GET",
          params: {
            request: btoa(JSON.stringify(request)),
          },
        }),
        providesTags: (result) => providesList(result?.catalogs, "HtzCatalog"),
      }),
      htzCatalogDelete: build.mutation<null, DeleteRequest>({
        query: (request) => ({
          url: `/htz/catalog`,
          method: "DELETE",
          body: request,
        }),
        invalidatesTags: ["HtzCatalog"],
      }),
      htzCatalogPutActiveCatalog: build.mutation<ActiveCatalog, ActiveCatalog>({
        query: (request) => ({
          url: `htz/active-catalog`,
          method: "PUT",
          body: request,
        }),
        invalidatesTags: ["HtzActiveCatalog"],
      }),
      htzCatalogShowActiveCatalog: build.query<
        ActiveCatalog,
        ShowActiveCatalogRequest
      >({
        query: (request) => ({
          url: `htz/active-catalog`,
          method: "GET",
          params: request,
        }),
        providesTags: ["HtzActiveCatalog"],
      }),
    }),
  });

export const {
  useHtzCatalogPutMutation,
  useHtzCatalogShowQuery,
  useHtzCatalogListQuery,
  useHtzCatalogDeleteMutation,
  useHtzCatalogPutActiveCatalogMutation,
  useHtzCatalogShowActiveCatalogQuery,
} = catalogApi;

export interface PutRequest extends Catalog {}

export interface ShowRequest {
  id: string;
}

export interface ListRequest {
  company?: Filter<string>;
  name?: Filter<string>;
  limit?: number;
  offset?: number;
}

export interface List {
  catalogs: Catalog[];
  count: number;
}

export interface DeleteRequest {
  id: string;
}

export interface ActiveCatalog {
  companyId: string;
  catalogId: string | null;
}

export interface ShowActiveCatalogRequest {
  companyId: string;
}
