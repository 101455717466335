import {
  addNode,
  AirHandlingUnit,
  changeNodes,
  Component,
  newFromTemplate,
} from "@/services/backend/htz/ahu/air-handling-unit";
import {
  Background,
  BackgroundVariant,
  Controls,
  NodeChange,
  NodeProps,
  Panel,
  ReactFlow,
  useReactFlow,
} from "@xyflow/react";
import { createContext, useContext, useMemo, useState } from "react";
import { Inspection } from "@/services/backend/htz/inspection/inspection";
import {
  Template,
  TemplateCatalog,
} from "@/services/backend/htz/ahu/template-catalog";
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuLabel,
  ContextMenuTrigger,
} from "@/shared/components/ui/context-menu";
import { ChevronDown, Trash2 } from "lucide-react";
import t from "@/lang/lang";
import { Switch } from "@/shared/components/ui/switch";
import { Label } from "@/shared/components/ui/label";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/shared/components/ui/popover";
import { Button } from "@/shared/components/ui/button";
import { Separator } from "@/shared/components/ui/separator";

export interface AhuComponentsSchemaProps {
  ahu: AirHandlingUnit;
  onAhuChange: (ahu: AirHandlingUnit) => void;
  catalog: TemplateCatalog;
  // inspection?: Inspection;
  // onInspectionChange?: (inspection: Inspection) => void;
}

export function AhuComponentsSchema({
  ahu,
  onAhuChange,
  catalog,
}: AhuComponentsSchemaProps) {
  const [inspectionMode, setInspectionMode] = useState<boolean>(false);

  const value = useMemo(
    () => ({
      ahu,
      onAhuChange,
      catalog,
      inspectionMode,
      setInspectionMode,
      immutable: false,
    }),
    [ahu, onAhuChange, catalog, inspectionMode, setInspectionMode],
  );

  // We use a context, since it is not possible to pass down
  // properties to nodes in the schema. Using a context allows
  // to define actions on the node or from a context menu (right click).
  return (
    <AhuSchemaContext.Provider value={value}>
      <AhuSchema />
    </AhuSchemaContext.Provider>
  );
}

interface AhuSchemaContextInterface {
  ahu: AirHandlingUnit;
  onAhuChange: (ahu: AirHandlingUnit) => void;
  catalog: TemplateCatalog;
  inspection?: Inspection;
  onInspectionChange?: (inspection: Inspection) => void;
  inspectionMode: boolean;
  setInspectionMode: (value: boolean) => void;
  immutable: boolean;
}

const AhuSchemaContext = createContext<AhuSchemaContextInterface>({
  ahu: {} as AirHandlingUnit,
  onAhuChange: () => null,
  catalog: {} as TemplateCatalog,
  inspectionMode: false,
  setInspectionMode: () => null,
  immutable: false,
});

function useAhuSchemaContext() {
  return useContext(AhuSchemaContext);
}

function AhuSchema() {
  const { ahu, onAhuChange, catalog, inspection, inspectionMode } =
    useAhuSchemaContext();
  const { components: nodes } = ahu;

  const onNodesChange = (changes: NodeChange<Component>[]) =>
    onAhuChange(changeNodes(ahu, changes));

  const nodeTypes = useMemo(
    () =>
      catalog.templates.reduce(
        // btw, TS is crazy...
        (p, c) => ({
          ...p,
          [c.type]: SchemaComponent,
        }),
        {},
      ),
    [catalog],
  );

  // 12 is a value working well together with tailwind sizes
  const gridGap = 12;

  return (
    <div className="w-max[1000px] h-[600px] border">
      <ReactFlow
        nodeTypes={nodeTypes}
        nodes={nodes}
        onNodesChange={onNodesChange}
        snapToGrid
        snapGrid={[gridGap, gridGap]}
        nodesDraggable={!inspectionMode}
      >
        {inspection && <InspectionModeSwitch />}
        <AddComponentBar />
        <Controls />
        <Background variant={BackgroundVariant.Dots} gap={gridGap} size={1} />
      </ReactFlow>
    </div>
  );
}

function InspectionModeSwitch() {
  const { inspectionMode, setInspectionMode } = useAhuSchemaContext();

  return (
    <Panel position="top-left">
      <div className="flex items-center space-x-2">
        <Switch
          id="airplane-mode"
          checked={inspectionMode}
          onCheckedChange={setInspectionMode}
        />
        <Label htmlFor="airplane-mode">{t("Inspektionsmodus")}</Label>
      </div>
    </Panel>
  );
}

function AddComponentBar() {
  const { ahu, onAhuChange, catalog } = useAhuSchemaContext();

  const onNodeAdd = (node: Component) => onAhuChange(addNode(ahu, node));

  const withoutGroup = catalog.templates.filter((temp) => temp.group === "");
  const groups = catalog.templates.reduce((prev, cur) => {
    // if without group, do nothing
    if (cur.group === "") {
      return prev;
    }

    // init group
    if (!prev.has(cur.group)) {
      prev.set(cur.group, []);
    }

    // add template
    prev.set(cur.group, [...prev.get(cur.group)!, cur]);

    return prev;
  }, new Map<string, Template[]>());

  // this roughly fits well, for 1-4 rows, with is the expected range
  const rows = Math.ceil(withoutGroup.length / 12);
  const separatorHeight = rows * 2 + rows * 8;

  return (
    <Panel
      position="top-center"
      className="flex min-w-max items-start gap-4 space-x-2 rounded-none border bg-background px-3 py-2 shadow-sm"
    >
      <div className="grid grid-cols-12 gap-2">
        {withoutGroup.map((template) => (
          <AddComponentButton
            template={template}
            onAdd={onNodeAdd}
            key={template.type}
          />
        ))}
      </div>
      <Separator orientation="vertical" className={`h-${separatorHeight}`} />
      <div className="flex flex-col space-y-2">
        {Array.from(groups.keys()).map((group) => (
          <AddComponentGroupPopover
            templates={groups.get(group)!}
            onAdd={onNodeAdd}
            key={group}
          />
        ))}
      </div>
    </Panel>
  );
}

function AddComponentGroupPopover({
  templates,
  onAdd,
}: {
  templates: Template[];
  onAdd: (c: Component) => void;
}) {
  if (templates.length === 0) {
    return null;
  }

  const groupName = templates[0].group;

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline" size="sm">
          <span>{groupName}</span>
          <ChevronDown className="ml-2 h-4 w-4" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="min-w-max" align="end">
        <div className="mb-4 text-sm font-bold">{groupName}</div>
        <div className="grid grid-cols-6 gap-2 bg-background">
          {templates.map((template) => (
            <div key={template.id}>
              <AddComponentButton template={template} onAdd={onAdd} />
              <div className="text-sm tracking-tighter">{template.details}</div>
            </div>
          ))}
        </div>
      </PopoverContent>
    </Popover>
  );
}

function AddComponentButton({
  template,
  onAdd,
}: {
  template: Template;
  onAdd: (c: Component) => void;
}) {
  const doAdd = () => {
    onAdd(newFromTemplate(template));
  };

  return (
    <button
      type="button"
      onClick={doAdd}
      className="flex items-center justify-center bg-background p-0 hover:bg-background"
    >
      <img
        src={`data:image/svg+xml;base64,${template.img}`}
        alt={template.name}
        className="h-9 max-w-9"
      />
    </button>
  );
}

function SchemaComponent({ id, data }: NodeProps<Component>) {
  const { deleteElements } = useReactFlow();

  // Components (the imgs) are expected to be defined in a
  // fixed aspect ratio and same size.
  // The sizeFactor is used to scale them as configured.
  const width = 12 * data.sizeFactor;

  const onDelete = async () => {
    await deleteElements({
      nodes: [{ id }],
    });
  };

  return (
    <div>
      <ContextMenu>
        <ContextMenuTrigger>
          <div className="relative">
            <img
              src={`data:image/svg+xml;base64,${data.img}`}
              alt={data.name}
              className={`w-${width} bg-white`}
            />
            <div className="absolute left-2 top-12 text-xs font-bold tracking-tight">
              {data.details}
            </div>
          </div>
        </ContextMenuTrigger>
        <ContextMenuContent>
          <ContextMenuLabel>{data.name}</ContextMenuLabel>
          <ContextMenuItem onClick={onDelete}>
            <Trash2 className="mr-2 h-4 w-4" />
            <span>{t("Löschen")}</span>
          </ContextMenuItem>
        </ContextMenuContent>
      </ContextMenu>
    </div>
  );
}
