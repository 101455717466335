import { backendApi } from "@/services/backend/api";
import { Filter } from "@/shared/lib/filter/filter";
import { providesEntity, providesList } from "@/services/backend/tag-helpers";
import { TemplateCatalog } from "@/services/backend/htz/ahu/template-catalog";

const offerApi = backendApi
  .enhanceEndpoints({
    addTagTypes: ["HtzAhuTemplateCatalog", "HtzActiveTemplateCatalog"],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      htzAhuTemplateCatalogPut: build.mutation<TemplateCatalog, PutRequest>({
        query: (request) => ({
          url: `/htz/ahu-template-catalog`,
          method: "PUT",
          body: request,
        }),
        invalidatesTags: (_result, _e, request) => [
          { type: "HtzAhuTemplateCatalog", id: request.id },
          "HtzAhuTemplateCatalog",
        ],
      }),
      htzAhuTemplateCatalogShow: build.query<TemplateCatalog, ShowRequest>({
        query: (request) => ({
          url: `/htz/ahu-template-catalog`,
          method: "GET",
          params: request,
        }),
        providesTags: providesEntity("HtzAhuTemplateCatalog"),
      }),
      htzAhuTemplateCatalogList: build.query<List, ListRequest>({
        query: (request) => ({
          url: `/htz/ahu-template-catalog/list`,
          method: "GET",
          params: {
            request: btoa(JSON.stringify(request)),
          },
        }),
        providesTags: (result) =>
          providesList(result?.catalogs, "HtzAhuTemplateCatalog"),
      }),
      htzAhuTemplateCatalogDelete: build.mutation<null, DeleteRequest>({
        query: (request) => ({
          url: `/htz/ahu-template-catalog`,
          method: "DELETE",
          body: request,
        }),
        invalidatesTags: ["HtzAhuTemplateCatalog"],
      }),
      htzAhuTemplateCatalogPutActiveCatalog: build.mutation<
        ActiveCatalog,
        ActiveCatalog
      >({
        query: (request) => ({
          url: `htz/ahu-template-catalog/active-catalog`,
          method: "PUT",
          body: request,
        }),
        invalidatesTags: ["HtzActiveTemplateCatalog"],
      }),
      htzAhuTemplateCatalogShowActiveCatalog: build.query<
        ActiveCatalog,
        ShowActiveCatalogRequest
      >({
        query: (request) => ({
          url: `htz/ahu-template-catalog/active-catalog`,
          method: "GET",
          params: request,
        }),
        providesTags: ["HtzActiveTemplateCatalog"],
      }),
    }),
  });

export const {
  useHtzAhuTemplateCatalogPutMutation,
  useHtzAhuTemplateCatalogShowQuery,
  useHtzAhuTemplateCatalogListQuery,
  useHtzAhuTemplateCatalogDeleteMutation,
  useHtzAhuTemplateCatalogPutActiveCatalogMutation,
  useHtzAhuTemplateCatalogShowActiveCatalogQuery,
} = offerApi;

export interface PutRequest extends TemplateCatalog {}

export interface ShowRequest {
  id: string;
}

export interface ListRequest {
  name?: Filter<string>;
  limit?: number;
  offset?: number;
}

export interface List {
  catalogs: TemplateCatalog[];
  count: number;
}

export interface DeleteRequest {
  id: string;
}

export interface ActiveCatalog {
  catalogId: string | null;
}

export interface ShowActiveCatalogRequest {}
