import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/shared/components/ui/tabs";
import t from "@/lang/lang";
import { AhuComponentsSchema } from "@/shared/components/domain/htz/ahu/ahu-components-schema";
import { AirHandlingUnit } from "@/services/backend/htz/ahu/air-handling-unit";
import { useState } from "react";
import {
  PutRequest,
  useHtzAirHandlingUnitPutMutation,
} from "@/services/backend/htz/ahu/service";
import { Button } from "@/shared/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { AhuComponentsTable } from "@/shared/components/domain/htz/ahu/ahu-components-table";
import {
  useHtzAhuTemplateCatalogShowActiveCatalogQuery,
  useHtzAhuTemplateCatalogShowQuery,
} from "@/services/backend/htz/ahu/template-catalog-service";
import { RefreshCw } from "lucide-react";

export function AhuComponents({ ahu }: { ahu: AirHandlingUnit }) {
  const {
    data: activeCatalog,
    isLoading: activeCatalogIsLoading,
    error: activeCatalogError,
  } = useHtzAhuTemplateCatalogShowActiveCatalogQuery({});
  const {
    data: catalog,
    isLoading: catalogIsLoading,
    error: catalogError,
  } = useHtzAhuTemplateCatalogShowQuery(
    { id: activeCatalog?.catalogId ?? "" },
    { skip: !activeCatalog || activeCatalog.catalogId === null },
  );

  const [request, setRequest] = useState<PutRequest>({ ...ahu });
  const [put, { isLoading, error }] = useHtzAirHandlingUnitPutMutation();

  if (activeCatalogError || catalogError) {
    return <RTKQueryErrorAlert error={activeCatalogError || catalogError} />;
  }

  if (activeCatalogIsLoading || catalogIsLoading) {
    return <Skeleton />;
  }

  const doUpdate = () => {
    if (isLoading) {
      return;
    }

    put(request);
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("Komponenten")}</CardTitle>
        <CardDescription>
          {t(
            "Die Komponenten der RLT-Anlage können als Liste, wie auch als Schema verwaltet werden. Bitte beachten Sie, dass wenn eine Komponente in der Liste hinzugefügt wird, diese im Schema noch an die passende Position platziert werden muss.",
          )}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Tabs defaultValue="schema">
          <TabsList>
            <TabsTrigger value="schema">{t("Schema")}</TabsTrigger>
            <TabsTrigger value="list">{t("Liste")}</TabsTrigger>
          </TabsList>
          <TabsContent value="schema">
            <AhuComponentsSchema
              ahu={request}
              onAhuChange={setRequest}
              catalog={catalog!}
            />
          </TabsContent>
          <TabsContent value="list">
            <AhuComponentsTable ahu={request} />
          </TabsContent>
        </Tabs>
        <RTKQueryErrorAlert error={error} />
      </CardContent>
      <CardFooter className="justify-end">
        <Button onClick={doUpdate}>{t("Speichern")}</Button>
      </CardFooter>
    </Card>
  );
}

function Skeleton() {
  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("Komponenten")}</CardTitle>
        <CardDescription>
          {t(
            "Die Komponenten der RLT-Anlage können als Liste, wie auch als Schema verwaltet werden. Bitte beachten Sie, dass wenn eine Komponente in der Liste hinzugefügt wird, diese im Schema noch an die passende Position platziert werden muss.",
          )}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <RefreshCw className="animate-spin" />
      </CardContent>
    </Card>
  );
}
