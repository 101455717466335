import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import { ChangeEvent, useState } from "react";
import { Button } from "@/shared/components/ui/button";
import { Edit, Plus } from "lucide-react";
import t from "@/lang/lang";
import { Label } from "@/shared/components/ui/label";
import { Input } from "@/shared/components/ui/input";
import { InputErrorMessages } from "@/shared/components/ui/input-error-messages";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  fieldErrors,
  rtkErrIsValidationError,
} from "@/shared/app-lib/errors/validation-error";
import { v4 } from "uuid";
import {
  addTemplate,
  SizeFactor,
  Template,
  updateTemplate,
} from "@/services/backend/htz/ahu/template-catalog";
import {
  useHtzAhuTemplateCatalogPutMutation,
  useHtzAhuTemplateCatalogShowQuery,
} from "@/services/backend/htz/ahu/template-catalog-service";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/shared/components/ui/select";

export function PutTemplateCatalogTemplateDialog({
  catalogId,
  template = undefined,
}: {
  catalogId: string;
  template?: Template;
}) {
  const { data: catalog } = useHtzAhuTemplateCatalogShowQuery({
    id: catalogId,
  });
  const [open, setOpen] = useState(false);
  const [updatedTemplate, setUpdatedTemplate] = useState<Template>(
    template ?? {
      id: v4(),
      catalogId,
      name: "",
      details: "",
      group: "",
      type: "",
      img: "",
      sizeFactor: 2,
    },
  );

  const [put, { isLoading, error, isSuccess, reset }] =
    useHtzAhuTemplateCatalogPutMutation();

  const validationError = rtkErrIsValidationError(error) ? error.data : null;
  const errorsFor = (field: string): string[] => {
    const prefixedField = `positions.${updatedTemplate.id}.${field}`;
    const errors = fieldErrors(validationError, prefixedField);
    return errors ?? [];
  };

  if (isSuccess) {
    setOpen(false);
    if (!template) {
      setUpdatedTemplate({
        id: v4(),
        catalogId,
        name: "",
        details: "",
        group: "",
        type: "",
        img: "",
        sizeFactor: 2,
      });
    }
    reset();
  }

  if (!catalog) {
    return template ? (
      <Button variant="outline" size="sm" disabled>
        <Edit className="h-5 w-5" />
      </Button>
    ) : (
      <Button size="sm" disabled>
        <Plus className="mr-2 h-5 w-5" />
        <span>{t("Vorlage")}</span>
      </Button>
    );
  }

  const onClick = () => {
    if (isLoading || !catalog) {
      return;
    }

    const updatedCatalog = template
      ? updateTemplate(catalog, updatedTemplate)
      : addTemplate(catalog, updatedTemplate);

    put(updatedCatalog);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {template ? (
          <Button variant="outline" size="sm">
            <Edit className="h-5 w-5" />
          </Button>
        ) : (
          <Button size="sm">
            <Plus className="mr-2 h-5 w-5" />
            <span>{t("Vorlage")}</span>
          </Button>
        )}
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {template ? t("Vorlage bearbeiten") : t("Vorlage hinzufügen")}
          </DialogTitle>
        </DialogHeader>
        <NameInput
          name={updatedTemplate.name}
          onNameChange={(name) =>
            setUpdatedTemplate({ ...updatedTemplate, name })
          }
          errors={errorsFor("name")}
        />
        <DetailsInput
          details={updatedTemplate.details}
          onDetailsChange={(details) =>
            setUpdatedTemplate({ ...updatedTemplate, details })
          }
          errors={errorsFor("details")}
        />
        <GroupInput
          group={updatedTemplate.group}
          onGroupChange={(group) =>
            setUpdatedTemplate({ ...updatedTemplate, group })
          }
          errors={errorsFor("group")}
        />
        <TypeInput
          type={updatedTemplate.type}
          onTypeChange={(type) =>
            setUpdatedTemplate({ ...updatedTemplate, type })
          }
          errors={errorsFor("type")}
        />
        <ImageInput
          img={updatedTemplate.img}
          onImgChange={(img) => setUpdatedTemplate({ ...updatedTemplate, img })}
          errors={errorsFor("img")}
        />
        <SizeFactorSelect
          sizeFactor={updatedTemplate.sizeFactor}
          onSizeFactorChange={(sizeFactor) =>
            setUpdatedTemplate({ ...updatedTemplate, sizeFactor })
          }
          errors={errorsFor("sizeFactor")}
        />
        <RTKQueryErrorAlert error={error} />
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">{t("Abbrechen")}</Button>
          </DialogClose>
          <Button onClick={onClick} disabled={isLoading}>
            {t("Speichern")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

function NameInput({
  name,
  onNameChange,
  errors,
}: {
  name: string;
  onNameChange: (name: string) => void;
  errors: string[];
}) {
  return (
    <div className="w-full items-center space-y-1.5">
      <Label>{t("Name")}</Label>
      <Input
        type="text"
        placeholder={t("Name")}
        value={name}
        onChange={(e) => onNameChange(e.target.value)}
      />
      <InputErrorMessages errors={errors} />
    </div>
  );
}

function DetailsInput({
  details,
  onDetailsChange,
  errors,
}: {
  details: string;
  onDetailsChange: (details: string) => void;
  errors: string[];
}) {
  return (
    <div className="w-full items-center space-y-1.5">
      <Label>{t("Details")}</Label>
      <Input
        type="text"
        placeholder={t("Details")}
        value={details}
        onChange={(e) => onDetailsChange(e.target.value)}
      />
      <span className="text-xs text-muted-foreground">
        {t(
          "Beispielsweise Filtertyp: ISO ePM2,5 70%; wird in Gruppen angezeigt",
        )}
      </span>
      <InputErrorMessages errors={errors} />
    </div>
  );
}

function GroupInput({
  group,
  onGroupChange,
  errors,
}: {
  group: string;
  onGroupChange: (details: string) => void;
  errors: string[];
}) {
  return (
    <div className="w-full items-center space-y-1.5">
      <Label>{t("Gruppe")}</Label>
      <Input
        type="text"
        placeholder={t("Details")}
        value={group}
        onChange={(e) => onGroupChange(e.target.value)}
      />
      <span className="text-xs text-muted-foreground">
        {t(
          "Optional. Ordnet die Vorlage in einer Gruppe, bei der Erstellung der RLT-Anlage.",
        )}
      </span>
      <InputErrorMessages errors={errors} />
    </div>
  );
}

function TypeInput({
  type,
  onTypeChange,
  errors,
}: {
  type: string;
  onTypeChange: (type: string) => void;
  errors: string[];
}) {
  return (
    <div className="w-full items-center space-y-1.5">
      <Label>{t("React Flow Type")}</Label>
      <Input
        type="text"
        placeholder={t("Type")}
        value={type}
        onChange={(e) => onTypeChange(e.target.value)}
      />
      <InputErrorMessages errors={errors} />
    </div>
  );
}

function ImageInput({
  img,
  onImgChange,
  errors,
}: {
  img: string;
  onImgChange: (img: string) => void;
  errors: string[];
}) {
  const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file: File = e.target.files[0];
      const data: string = await file.text();
      const encoded = btoa(data);
      onImgChange(encoded);
      e.target.value = "";
      e.target.files = null;
    }
  };

  return (
    <div className="w-full items-center space-y-1.5">
      <Label>{t("Piktogramm")}</Label>
      <img src={`data:image/svg+xml;base64,${img}`} alt="" className="h-20" />
      <Input
        type="file"
        placeholder={t("Piktogramm")}
        accept=".svg"
        onChange={onChange}
      />
      <InputErrorMessages errors={errors} />
    </div>
  );
}

function SizeFactorSelect({
  sizeFactor,
  onSizeFactorChange,
  errors,
}: {
  sizeFactor: SizeFactor;
  onSizeFactorChange: (sizeFactor: SizeFactor) => void;
  errors: string[];
}) {
  return (
    <div className="w-full items-center space-y-1.5">
      <Label>{t("Größenfaktor (1 | 2 | 4)")}</Label>
      <Select
        value={sizeFactor.toString()}
        onValueChange={(value) => {
          const cast = Number(value);
          switch (cast) {
            case 1:
            case 2:
            case 4:
              onSizeFactorChange(cast);
              break;
            default:
              break;
          }
        }}
      >
        <SelectTrigger>
          <SelectValue placeholder={t("Größenfaktor")} />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectItem value="1">1</SelectItem>
            <SelectItem value="2">2</SelectItem>
            <SelectItem value="4">4</SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>
      <InputErrorMessages errors={errors} />
    </div>
  );
}
